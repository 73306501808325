import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Emery6thbday from "./components/Emery6thbday";
import EmilyGraduation from "./components/EmilyGraduation";
import ErrorBoundary from "./components/ErrorBoundary";
import ErrorPage from "./components/ErrorPage";
import NoPublicAccess from "./components/NoPublicAccess";
import OctNineteenTwentyFourGame from "./components/OctNineteenTwentyFourGame";
import OctTenTwentyFourGame from "./components/OctTenTwentyFourGame";
import OctTwentySixTwentyFourGame from "./components/OctTwentySixTwentyFourGame";
import PhotoAlbum from "./components/PhotoAlbum";
import YashPhotoshoot from "./components/YashPhotoshoot";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/emerys6thbirthday"
            element={
              <>
                <ErrorBoundary />
                <Emery6thbday />
              </>
            }
          />
          <Route
            path="/emilysgraduation"
            element={
              <>
                <ErrorBoundary />
                <EmilyGraduation />
              </>
            }
          />
          <Route
            path="/cg2a9LA4qbfz/albums"
            element={
              <>
                <ErrorBoundary />
                <PhotoAlbum />
              </>
            }
          />
          <Route
            path="/2024-10-10Game"
            element={
              <>
                <ErrorBoundary />
                <OctTenTwentyFourGame />
              </>
            }
          />
          <Route
            path="/2024-10-19Game"
            element={
              <>
                <ErrorBoundary />
                <OctNineteenTwentyFourGame />
              </>
            }
          />
          <Route
            path="/F5Fa9LA4qbfb/2024-10-26Game"
            element={
              <>
                <ErrorBoundary />
                <OctTwentySixTwentyFourGame />
              </>
            }
          />
          <Route
            path="/yashphotoshoot"
            element={
              <>
                <ErrorBoundary />
                <YashPhotoshoot />
              </>
            }
          />
          <Route
            path="/error"
            element={
              <>
                <ErrorBoundary />
                <ErrorPage />
              </>
            }
          />
          <Route
            path="/"
            element={
              <>
                <ErrorBoundary />
                <NoPublicAccess />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
