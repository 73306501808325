import React, { useState } from "react";
import Modal from "react-modal";
import albums from "../albums.json";
import "./PhotoGallery.css";

const album = albums[2];
const photos = album.photos;
const title = album.title;

Modal.setAppElement("#root");

const PhotoGallery = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState("");
  const [currentPhotoWidth, setCurrentPhotoWidth] = useState(0);
  const [currentPhotoHeight, setCurrentPhotoHeight] = useState(0);
  const getMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  const openModal = (photo) => {
    setCurrentPhoto(photo);
    setModalIsOpen(true);
    // get image size
    getMeta(photo, (err, img) => {
      console.log(img.naturalWidth, img.naturalHeight);
      setCurrentPhotoWidth(img.naturalWidth);
      setCurrentPhotoHeight(img.naturalHeight);
    });
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentPhoto("");
  };

  return (
    <div>
      <h1 style={{ color: "black" }}>{title}</h1>
      <div className="photo-gallery">
        {photos.map((photo, index) => (
          <img
            key={index}
            src={photo}
            alt={`${index + 1}`}
            className="thumbnail"
            onClick={() => openModal(photo)}
          />
        ))}

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Enlarged Photo"
          className="modal"
          overlayClassName="overlay"
        >
          <button onClick={closeModal} className="close-button">
            Close
          </button>
          {currentPhotoWidth > currentPhotoHeight ? (
            <img
              src={currentPhoto}
              alt="Enlarged"
              style={{
                maxWidth: "100%", // Full width
                height: "auto", // Maintains aspect ratio
              }}
            />
          ) : (
            <img
              src={currentPhoto}
              alt="Enlarged"
              style={{
                maxWidth: "50%", // Full width
                height: "auto", // Maintains aspect ratio
              }}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};

export default PhotoGallery;
